/** @jsx jsx */
import { useEffect, useState, useRef, useCallback } from 'react'
import { Grid, Flex, jsx, Text, Button, Box, AspectRatio } from 'theme-ui'
import { keyframes } from '@emotion/core'
import ky from 'ky'
import { StyledLink } from '@components/Links'

import PageHeader from '@components/PageHeader'
import Layout from '@components/Layout'
import SEO from '@components/SEO'
import { useUIStore } from '@stores/ui'
import WeTransferLogo from '../components/WeTransferLogo'

const fadeIn = keyframes(`
from {
  opacity: 0;
}
to {
  opacity: 1;
}`)

const useStreams = () => {
  const streams = useUIStore((state) => state.streams)
  const setStreams = useUIStore((state) => state.actions.setStreams)
  const hasNext = useUIStore((state) => state.hasNext)
  const setHasNext = useUIStore((state) => state.actions.setHasNext)
  const hasRendered = useRef(streams.length > 0)

  const loadMore = useCallback(async () => {
    if (hasRendered.current && !hasNext) return
    try {
      const data = await ky(
        hasNext || 'https://api.mixcloud.com/radioradioradioradio/cloudcasts/'
      ).json()
      if (data.paging && data.paging.next) {
        setHasNext(data.paging.next)
      } else {
        setHasNext(undefined)
      }
      setStreams(data.data)
    } catch (error) {
      console.error(error)
    }
  }, [setStreams, hasNext, setHasNext])

  useEffect(() => {
    if (!hasRendered.current) loadMore()
    hasRendered.current = true
  }, [loadMore])

  return [streams, hasNext, loadMore]
}

const Stream = ({ stream }) => {
  const setPlayerURL = useUIStore((state) => state.actions.setPlayerURL)
  const playerSlug = useUIStore((state) => state.playerSlug)
  const isActive = stream.slug === playerSlug

  return (
    <Grid
      sx={{
        '.info': {
          display: 'none',
        },
        '&:hover': {
          cursor: 'pointer',
          '.info': {
            display: ['none', 'block'],
          },
          '.cover': {
            opacity: 0.5,
          },
        },
        animation: `${fadeIn} 0.3s ease`,
        border: '1px solid black',
        transition: 'background 0.3s ease',
        bg: isActive ? 'white' : 'var(--main-color)',
        gridTemplateColumns: ['1fr 3fr', '1fr'],
        gridTemplateRows: ['1fr', 'max-content 1fr'],
        gap: 0,
      }}
      onClick={() => {
        setPlayerURL(stream.slug)
      }}
    >
      <AspectRatio
        ratio={1}
        sx={{
          backgroundImage: [
            `url(${stream.pictures.large})`,
            `url(${stream.pictures.large})`,
            `url(${stream.pictures.large})`,
            `url(${stream.pictures.extra_large})`,
          ],
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundColor: '#aaa',
          flexShrink: 0,
        }}
      >
        <Box
          className="cover"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bg: 'black',
            transition: 'opacity 0.3s ease',
            opacity: 0,
          }}
        />
        <Box
          className="info"
          sx={{
            px: 2,
            py: 1,
            fontSize: 0,
            position: 'absolute',
            bottom: 1,
            left: 1,
            color: 'white',
          }}
        >
          {Math.floor(stream.audio_length / 60)} min
        </Box>

        <Box
          className="info"
          sx={{
            py: 1,
            px: 2,
            bg: 'var(--main-color)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            fontSize: 0,
            transform: 'translate(-50%, -50%)',
            textTransform: 'uppercase',
            border: '1px solid black',
            letterSpacing: '0.02em',
          }}
        >
          {isActive ? 'Stop' : 'Play'}
        </Box>
      </AspectRatio>
      <Text
        as="div"
        sx={{
          display: 'grid',
          justifyItems: 'flex-start',
          fontSize: [2, 1],
          px: 2,
          py: 2,
          lineHeight: 1.2,
          borderTop: ['none', '1px solid black'],
        }}
      >
        {stream.name}
        <Box
          sx={{
            fontSize: 0,

            display: ['block', 'none'],
            alignSelf: 'flex-end',
          }}
        >
          {Math.floor(stream.audio_length / 60)} min
        </Box>
      </Text>
    </Grid>
  )
}

const MixesPage = () => {
  const [streams, hasNext, loadMore] = useStreams()

  return (
    <Layout>
      <SEO title="Mixes and livesets" />
        <PageHeader
          title="Listen to our livesets"
          spaceBetween
          padding
          extra={
            <div sx={{ float: 'right' }}>
              <WeTransferLogo />
              <StyledLink to="https://www.mixcloud.com/radioradioradioradio/">Full Mixcloud archive</StyledLink>
            </div>
          }
         />
         <Box py={0} px={3} sx={{ minHeight: '100vh' }}>

        <Grid
          sx={{
            gridTemplateColumns: [
              '1fr',
              '1fr 1fr 1fr',
              '1fr 1fr 1fr 1fr',
              '1fr 1fr 1fr 1fr 1fr',
            ],
          }}
          gap={[1, 3]}
        >
          {streams.map((stream) => (
            <Stream stream={stream} key={`stream-${stream.key}`} />
          ))}
        </Grid>

        {hasNext && (
          <Button
            sx={{
              background: 'transparent',
              border: 'none',
              color: 'black',
              fontFamily: 'body',
              my: 3,
              '&:hover': {
                cursor: 'pointer',
                fontStyle: 'italic',
              },
            }}
            onClick={() => {
              loadMore()
            }}
          >
            &rarr; Load more
          </Button>
        )}
        </Box>
    </Layout>
  )
}

export default MixesPage
